<template>
  <div>
    <el-dialog :visible.sync="dialogTableVisible" :close-on-click-modal="false" :close-on-press-escape="false" :before-close="handleClose" width="45%">
      <div slot="title" class="dialog-header">
        <span>创建任务</span>
      </div>
      <div class="dialog-body" v-loading="btnloading" element-loading-text="正在努力提交中！" element-loading-spinner="el-icon-loading">
        <div>
          <span style="color: red">*</span>
          <span>任务名称</span>
          <el-input class="m-t-16 m-b-28" v-model="taskName" placeholder="请输入任务名称"></el-input>
        </div>
        <!-- 选择网站 -->
        <div>
          <div class="flex-c m-b-16">
            <span>任务网站({{ siteListData.length }})</span>
            <span class="m-l-24 m-r-24" style="color: #EA3342;">注意：仅支持添加政务类网站</span>
            <div class="sel-btn" @click="selectValut(1)">选择网站</div>
          </div>
          <div class="flex-c m-b-16">
            <el-autocomplete style="width: 44%;margin-right: 1rem;" class="" v-model="searchSite.webName" popper-class="my-autocomplete"
                             :fetch-suggestions="querySearchWeb" placeholder="请输入网站名称" @select="handleSelect">
              <template slot-scope="{ item }">
                <div class="flex">
                  <div class="w50 text-hide">网站名称 ：{{ item.accountName}}</div>
                  <span class="w50 text-hide">网站地址 : {{ item.homeUrl }}</span>
                </div>
              </template>
            </el-autocomplete>
            <el-autocomplete style="width: 55%;" class="" v-model="searchSite.webUrl" popper-class="my-autocomplete" :fetch-suggestions="querySearchWeb"
                             placeholder="请输入网站地址" @select="handleSelect">
              <template slot-scope="{ item }">
                <div class="flex">
                  <div class="w50 text-hide">网站名称 ：{{ item.accountName }}</div>
                  <span class="w50 text-hide">网站地址 : {{ item.homeUrl }}</span>
                </div>
              </template>
            </el-autocomplete>
            <!-- <div class="add-btn">添加</div> -->
          </div>
          <div class="show-div my-scrollbar" v-if="siteListData.length>0">
            <el-row :gutter="0" v-for="item in siteListData " :key="item.accountId">
              <el-col :span="11" :offset="0">
                <div class="el-c-hui text-hide">{{item.accountName}}</div>
              </el-col>
              <el-col :span="11" :offset="0">
                <div class="el-c-hui cur text-hide" style="color: #2E59EC;">
                  {{ item.homeUrl }}
                </div>
              </el-col>
              <el-col :span="2" :offset="0">
                <div class="el-c-hui cur" style="color: red;" @click="delSiteList(item.accountId)">
                  删除
                </div>
              </el-col>
            </el-row>

          </div>
        </div>
        <!-- 选择公众号 -->
        <div>
          <div class="flex-c m-b-16 m-t-16">
            <span>任务账号({{ accountListData.length }})</span>
            <span class="m-l-24 m-r-24" style="color: #EA3342;">注意：仅支持添加政务类微信公众号</span>
            <div class="sel-btn" @click="selectValut(2)">选择公众号</div>
          </div>
          <div class="flex-c m-b-16">
            <el-autocomplete style="width: 100%" class="" v-model="searchSite.accountName" popper-class="my-autocomplete"
                             :fetch-suggestions="querySearchAccount" placeholder="请输入账号名称" @select="handleAccount">
              <template slot-scope="{ item }">
                <div class="flex">
                  <div class="w50 text-hide">来源 ：微信公众号</div>
                  <span class="w50 text-hide">账号昵称 : {{ item.accountName }}</span>
                </div>
              </template>
            </el-autocomplete>
            <!-- <div class="add-btn">添加</div> -->
          </div>
          <div class="show-div my-scrollbar" v-if="accountListData.length>0">
            <el-row :gutter="0" v-for="item in accountListData " :key="item.accountId">
              <el-col :span="4" :offset="0">
                <div class="el-c-hui">微信公众号</div>
              </el-col>
              <el-col :span="9" :offset="0">
                <div class="el-c-hui  flex-c">
                  <img class="m-r-12" style="vertical-align: middle;" src="@/assets/img/zhang/morentx.png" alt="">
                  <span class="text-hide">{{item.accountName}}</span>
                </div>
              </el-col>
              <el-col :span="9" :offset="0">
                <div class="el-c-hui cur text-hide" style="color: #2E59EC;">
                  {{item.homeUrl}}
                </div>
              </el-col>
              <el-col :span="2" :offset="0">
                <div class="el-c-hui cur" style="color: red;" @click="delAccountList(item.accountId)">
                  删除
                </div>
              </el-col>
            </el-row>

          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消 </el-button>
        <el-button @click="submitTask" :loading="btnloading" type="primary">确 定</el-button>
      </span>
    </el-dialog>
    <selSite ref="selSite" :title="'选择网站'" @getSubmit="getSubmitSite"></selSite>
    <selSite ref="selAccount" :title="'选择公众号'" @getSubmit="getSubmitAccount"></selSite>
  </div>
</template>

<script>
import selSite from './components/selSite.vue';
import Api from '@/api/errLog/index';
export default {
  components: {
    selSite,
  },
  props: {
    remainingCount: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      dialogTableVisible: false,
      btnloading: false,
      taskName: '',
      searchSite: {
        webName: '',
        webUrl: '',
        accountName: ''
      },
      siteListData: [],
      accountListData: []
    };
  },
  methods: {
    async submitTask() {
      let data = {
        accountWangzhan: this.siteListData,
        accountWeixingongzhonghao: this.accountListData,
        name: this.taskName
      }
      if (!data.name) return this.$message.error('任务名称不能为空!')
      if (data.accountWangzhan.length == 0 && data.accountWeixingongzhonghao.length == 0)
        return this.$message.error('巡查网站和公众号不能同时为空!')
      if (data.accountWangzhan.length + data.accountWeixingongzhonghao.length > this.remainingCount)
        return this.$message.error('巡查网站和公众号已超出巡查额度,请联系管理员提升额度!')
      this.btnloading = true
      let res = await Api.createTask(data)
      if (res?.data?.code == 200) {
        this.handleClose()
        
        this.$message.success('创建成功')
        this.$emit('createTask')
      }
    },
    handleClose() {
      this.dialogTableVisible = false;
      this.btnloading = false;
      // 重置数据
      this.siteListData = []
      this.accountListData = []
      this.taskName = ''
      this.searchSite = {
        webName: '',
        webUrl: '',
        accountName: ''
      }
      this.$refs.selSite.handleClose()
      this.$refs.selAccount.handleClose()
    },
    async querySearchWeb(qs, cb) {
      if (!qs) return
      let data = {
        "platformTypeList": ["1"],
        "bodyTypeList": ["2", "1", "3", "6", "51", "61", "16"],
        // "accountName": qs,
        // "domainName": qs,
        "pageNo": 1,
        "pageSize": 100,
        "statHarm": true
      }
      if (this.searchSite.webName) data.accountName = this.searchSite.webName
      if (this.searchSite.webUrl) data.domainName = this.searchSite.webUrl
      let res = await Api.getWebsiteList(data)
      if (res?.data?.code == 200) {
        cb(res.data.data.list);
      }

    },
    async querySearchAccount(qs, cb) {

      if (!qs) return
      let data = {
        "platformTypeList": ["3"],
        "bodyTypeList": ["2", "1", "3", "6", "51", "61", "16"],
        // "accountName": qs,
        // "domainName": qs,
        "pageNo": 1,
        "pageSize": 100,
        "statHarm": true
      }
      if (this.searchSite.accountName) data.accountName = this.searchSite.accountName
      let res = await Api.getWebsiteList(data)
      if (res?.data?.code == 200) {
        cb(res.data.data.list);
      }
    },
    handleAccount(val) {
      this.accountListData = [val, ...this.accountListData]
      //给this.siteListData去重
      this.accountListData = this.accountListData.reduce((acc, cur) => {
        const x = acc.find(item => item.accountId === cur.accountId);
        if (!x) {
          return acc.concat([cur]);
        } else {
          return acc;
        }
      }, []);
    },
    delAccountList(id) {
      // 删除siteList中id符合的数据
      this.accountListData = this.accountListData.filter(item => item.accountId !== id);
    },
    handleSelect(val) {
      this.siteListData = [val, ...this.siteListData]
      //给this.siteListData去重
      this.siteListData = this.siteListData.reduce((acc, cur) => {
        const x = acc.find(item => item.accountId === cur.accountId);
        if (!x) {
          return acc.concat([cur]);
        } else {
          return acc;
        }
      }, []);
    },
    delSiteList(id) {
      // 删除siteList中id符合的数据
      this.siteListData = this.siteListData.filter(item => item.accountId !== id);
    },
    selectValut(val) {
      if (val == 1) {
        this.$refs.selSite.dialogTableVisible = true;
      } else {
        this.$refs.selAccount.dialogTableVisible = true;
      }
    },
    getSubmitSite(list) {
      this.siteListData = [...list,...this.siteListData]
      // this.$refs.selSite.dialogTableVisible = false
    },
    getSubmitAccount(list) {
      this.accountListData = [...list,...this.accountListData]
      // this.$refs.selAccount.dialogTableVisible = false
    }
  },
}
</script>

<style lang="scss" scoped>
.sel-btn {
  width: 98px;
  height: 28px;
  background: #fff1e8;
  border-radius: 4px;
  border: 1px solid #ed731f;
  font-size: 14px;
  color: #ed731f;
  text-align: center;
  line-height: 28px;
  cursor: pointer;
}
.add-btn {
  width: 80px;
  height: 36px;
  background: #f58030;
  border-radius: 4px;
  line-height: 36px;
  color: #fff;
  text-align: center;
  cursor: pointer;
}
.el-c-hui {
  background-color: #f5f6fa;
  border: 1px solid #fff;
  width: 100%;
  height: 48px;
  padding: 14px 16px;
}
.show-div {
  max-height: 144px;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
<style >
.my-autocomplete {
  min-width: 780px !important;
}
</style>