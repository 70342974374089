import request from '../request.js';

// 获取分页列表
export function getList(data) {
  return request({
    url: "/module-listener-complex/misreHistory/page",
    method: 'post',
    data
  })
}

// 删除iten
export function deleteItem(data) {
  return request({
    url: "/module-listener-complex/misreHistory/delete/"+data,
    method: 'get'
  })
}

//创建任务
export function createTask(data) {
  return request({
    url: "/module-listener-complex/misreHistory/saveMisrperHistoryJob",
    method: 'post',
    data
  })
}

//剩余账号额度
export function getRemainingCount() {
  return request({
    url: "/module-listener-complex/misreHistory/getRemainingCount",
    method: 'get'
  })
}
//查询网站/公众号列表
export function getWebsiteList(data) {
  return request({
    url: "/module-account/account/queryPage",
    method: 'post',
    data
  })
}


export default {
  getList, deleteItem, createTask, getRemainingCount, getWebsiteList
}